.categories-container {
  overflow: hidden; /* Hide the scrollbar */
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  position: relative;
}

.category {
  float: left;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  background-color: #037207;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  h2 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
}
// for commit
.arrow {
  display: none;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: red;
  z-index: 1; /* Ensure arrows appear above categories */
}

.arrow.prev {
  display: none;
  left: 0;
  background-color: red;
  transform: translateY(-50%);
}

.arrow.next {
  right: 0;
  background-color: red;
  transform: translateY(-50%);
}

.food-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 60px;

  .food-item {
    background-color: antiquewhite;
    flex-basis: calc(48% - 10px); /* Adjust the initial size, considering margin */
    margin-bottom: 20px;
    border: 1px solid #ddd;
    margin: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column; /* Make items stack vertically */
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .image-wrapper {
      flex: 1;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0; 
        padding: 0; 
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    .food-details {
      flex: 1;
      padding: 0 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; 
      padding: 5px;

      h3 {
        text-align: left;
        font-size: 18px;
        margin-bottom: 5px;
      }

      .bottom-card {
        .left-side-card {
          text-align: left;
        }

        .right-side-card {
          text-align: right;
        }
      }

      .price {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #e44d26;
      }
      .schedule {
        font-size: 14px; 
        margin-top: 5px; 
        color: #333;
      }
    }
    &.blurred {
     background-color: #80808096;// Increase the blur intensity as needed
      opacity: 1.8; // Adjust the opacity to make the item more translucent
    }
    .not-in-schedule{

    }
  }
}

@media (max-width: 768px) {
  .food-item {
    flex-basis: calc(100% - 10px); 
  }
}

.button {
  background-color: #4caf50;
  width: 100%;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #034206;
  }
}

.out-of-stock {
  color: red; /* Set the text color to red */
}
