.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f0f0f0;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: auto;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  margin: 30px auto 0;
  .icon {
    font-size: 24px;
    color: #45a049;
    cursor: pointer;
.cart-counter{
  color: red;
  font-weight: bold;
  font-size: 25px;
}
    @media (min-width: 375px) {
      font-size: 30px;
    }

    &:hover {
      color: red;
    }
  }

  @media (max-width: 375px) {
    .footer {
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      


      .icon {
        margin: 5px; /* Add margin between icons */
      }
    }
  }
}
