
.table-container {
    max-width: 600px; // Adjust the maximum width as per your design
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    h1 {
      font-size: 24px;
      margin-bottom: 16px;
    }
  
    p {
      font-size: 16px;
      margin-bottom: 12px;
    }
  
    button {
      background-color: #007bff;
      color: #ffffff;
      padding: 10px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  